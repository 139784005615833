/* eslint-disable no-restricted-imports */
import React from 'react';
import useStyles from '@common_alert/style';
import classNames from 'classnames';
import { Alert, AlertTitle } from '@material-ui/lab';

import InfoIcon from '@material-ui/icons/Info';
import CancelIcon from '@material-ui/icons/Cancel';
import WarningIcon from '@material-ui/icons/Warning';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const AlertContent = ({
    severity = 'info', // error || warning || info || success
    titleMultiple = [],
    title,
    info,
    close = '',
    containerClassName = '',
    contentClassName = '',
    titleClassName = '',
    icon,
    align = 'center',
}) => {
    const classes = useStyles();
    const iconSeverity = {
        error: <CancelIcon classes={{ root: classNames(classes.icon, severity) }} />,
        info: <InfoIcon classes={{ root: classNames(classes.icon, severity) }} />,
        success: <CheckCircleIcon classes={{ root: classNames(classes.icon, severity) }} />,
        warning: <WarningIcon classes={{ root: classNames(classes.icon, severity) }} />,
    };
    return (
        <Alert
            icon={icon || iconSeverity[severity]}
            severity={severity}
            className={classNames(classes.containerAlert, align, severity, containerClassName)}
            onClose={close}
        >
            {titleMultiple.length > 0 ? titleMultiple.map((e) => (
                <div className={classNames(classes.divContent, contentClassName)}>
                    <AlertTitle className={classNames(classes.title, titleClassName)}>{e.title}</AlertTitle>
                    {e.info}
                </div>
            )) : (
                <div className={classNames(classes.divContent, contentClassName)}>
                    {title && <AlertTitle className={classNames(classes.title, titleClassName)}>{title}</AlertTitle>}
                    {info}
                </div>
            )}
        </Alert>
    );
};

export default AlertContent;
