/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Cookies from 'js-cookie';
import clsx from 'clsx';

import Button from '@common_button';
import TextField from '@common_textfield';
import PhoneInput from '@common_phoneinput';
import Alert from '@common_alert';
import PasswordStrength from '@common_passwordstrength';

import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import GetScore from '@helper_passwordstrength';
import useStyles from '@modules/dashboard/pages/edit/components/style';

const EditUserFormContent = (props) => {
    const {
        formik,
        t,
        setDialCode,
        forceResetPassword,
    } = props;
    const classes = useStyles();
    const router = useRouter();
    const firstRenderPhone = React.useRef(true);

    const [changeEmail, setChangeEmail] = useState(false);
    const [changePassword, setChangePassword] = useState(Cookies.getJSON('cdt')?.force_reset_password ?? false);
    const [passwordError, setPasswordError] = useState('');
    const [passwordErrorStatus, setPasswordErrorStatus] = useState('');
    const [showPassword, setShowPassword] = useState({
        current: false,
        new: false,
        confirm: false,
    });

    const handleEmailField = (event) => {
        setChangeEmail(event.target.checked);
        formik.setFieldValue('changeEmail', !formik.values.changeEmail);
    };

    const handlePasswordField = (event) => {
        setChangePassword(event.target.checked);
        formik.setFieldValue('changePassword', !formik.values.changePassword);
    };

    useEffect(() => {
        if (changePassword) {
            const passwordScore = GetScore(formik.values.newPassword, 8, 3);
            setPasswordError(`${passwordScore.message ? `${passwordScore.message} or password too common` : ''}`);
            setPasswordErrorStatus(passwordScore.status);
        }
    }, [formik.values.newPassword]);

    return (
        <div>
            <Button
                className={clsx(classes.btnBack, 'buttonBack')}
                onClick={() => router.push('/')}
                variant="contained"
                style={{ marginRight: 16 }}
            >
                <ChevronLeftIcon style={{
                    fontSize: 30,
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                }}
                />
            </Button>
            <h2 className={classes.titleTop}>{t('dashboard:Edit_My_Account')}</h2>
            {forceResetPassword
            && (
                <Alert
                    info={t('dashboard:Please_change_your_default_password_to_secure_your_account')}
                    containerClassName={classes.alertContainer}
                    severity="warning"
                />
            )}
            <Paper className={classes.container}>
                <div className={classes.content}>
                    <div className={classes.nameGrid}>
                        <div className={classes.nameGridCol}>
                            <TextField
                                autoFocus
                                className={classes.fieldInput}
                                name="firstname"
                                label={t('dashboard:Firstname')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formik.values.firstname}
                                onChange={formik.handleChange}
                                error={!!(formik.touched.firstname && formik.errors.firstname)}
                                helperText={(formik.touched.firstname && formik.errors.firstname) || ''}
                            />
                        </div>
                        <div className={classes.nameGridCol}>
                            <TextField
                                className={classes.fieldInput}
                                name="lastname"
                                label={t('dashboard:Lastname')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formik.values.lastname}
                                onChange={formik.handleChange}
                                error={!!(formik.touched.lastname && formik.errors.lastname)}
                                helperText={(formik.touched.lastname && formik.errors.lastname) || ''}
                            />
                        </div>
                    </div>

                    <TextField
                        className={clsx(classes.fieldInput, 'fieldInputSpace')}
                        name="customer_loc_code"
                        label={t('dashboard:Location_Code')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled
                        value={formik.values.customer_loc_code}

                    />

                    <TextField
                        className={clsx(classes.fieldInput, 'fieldInputSpace')}
                        name="customer_channel_code"
                        label={t('dashboard:Channel_Code')}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        disabled
                        value={formik.values.channel_code}
                    />

                    <PhoneInput
                        name="phone_number"
                        value={formik.values.phone_number}
                        onChange={(e, c) => {
                            formik.setFieldValue('phone_number', e);
                            setDialCode(c.dialCode);
                        }}
                        error={!!(formik.touched.phone_number && formik.errors.phone_number)}
                        helperText={(formik.touched.phone_number && formik.errors.phone_number) || ''}
                        containerClass={classes.fieldPhoneContainer}
                        rootClasses={classes.fieldPhoneRoot}
                        specialLabel="Phone Number"
                        onFocus={(e, c) => {
                            setDialCode(c.dialCode);
                            if (firstRenderPhone.current) {
                                firstRenderPhone.current = false;
                                e.target.blur();
                            }
                        }}
                        inputProps={{ autoFocus: true }}
                    />

                    <FormControl component="fieldset" className={clsx(classes.formControl, 'formControlSpace')}>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox className={clsx(classes.checkboxToggle)} checked={changeEmail} onChange={handleEmailField} name="changeEmail" />}
                                label={t('dashboard:Change_Email')}
                            />

                            <FormControlLabel
                                control={<Checkbox className={clsx(classes.checkboxToggle)} checked={changePassword} onChange={handlePasswordField} name="changePassword" />}
                                label={t('dashboard:Change_Password')}
                            />

                        </FormGroup>
                    </FormControl>

                    {changeEmail === true
                        && (
                            <TextField
                                className={clsx(classes.fieldInput, 'fieldInputSpace')}
                                name="email"
                                label={t('dashboard:Email')}
                                placeholder="name@example.com"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={!!(formik.touched.email && formik.errors.email)}
                                helperText={(formik.touched.email && formik.errors.email) || ''}
                            />
                        )}

                    {(changeEmail === true || changePassword === true)
                        && (
                            <TextField
                                className={clsx(classes.fieldInput, 'fieldInputSpace')}
                                name="currentPassword"
                                label={t('dashboard:Current_Password')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                value={formik.values.currentPassword}
                                onChange={formik.handleChange}
                                error={!!(formik.touched.currentPassword && formik.errors.currentPassword)}
                                helperText={(formik.touched.currentPassword && formik.errors.currentPassword) || ''}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="start">
                                            <IconButton
                                                onClick={() => setShowPassword((prev) => ({ ...prev, current: !showPassword.current }))}
                                                className={classes.showButton}
                                            >
                                                {showPassword.current ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                type={showPassword.current ? 'text' : 'password'}
                            />
                        )}

                    {changePassword === true
                        && (
                            <>
                                <TextField
                                    className={clsx(classes.fieldInput, 'fieldInputSpace')}
                                    name="newPassword"
                                    label={t('dashboard:New_Password')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={formik.values.newPassword}
                                    onChange={formik.handleChange}
                                    error={passwordError !== '' || !!(formik.touched.newPassword && formik.errors.newPassword) || !!passwordError}
                                    helperText={(formik.touched.newPassword && formik.errors.newPassword) || passwordError || ''}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton
                                                    onClick={() => setShowPassword((prev) => ({ ...prev, new: !showPassword.new }))}
                                                    className={classes.showButton}
                                                >
                                                    {showPassword.new ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    type={showPassword.new ? 'text' : 'password'}
                                />
                                <PasswordStrength passwordErrorStatus={passwordErrorStatus} />

                                <TextField
                                    className={clsx(classes.fieldInput, 'fieldInputSpace')}
                                    name="confirmPassword"
                                    label={t('dashboard:Confirm_New_Password')}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    value={formik.values.confirmPassword}
                                    onChange={formik.handleChange}
                                    error={!!(formik.touched.confirmPassword && formik.errors.confirmPassword)}
                                    helperText={(formik.touched.confirmPassword && formik.errors.confirmPassword) || ''}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton
                                                    onClick={() => setShowPassword((prev) => ({ ...prev, confirm: !showPassword.confirm }))}
                                                    className={classes.showButton}
                                                >
                                                    {showPassword.confirm ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    type={showPassword.confirm ? 'text' : 'password'}
                                />
                            </>
                        )}
                </div>
                <div className={classes.formFieldButton}>
                    <Button
                        onClick={passwordError ? null : formik.handleSubmit}
                        variant="contained"
                        buttonType="primary-rounded"
                    >
                        {t('dashboard:Submit')}
                    </Button>
                </div>
            </Paper>
        </div>
    );
};

export default EditUserFormContent;
