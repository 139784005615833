import makeStyles from '@material-ui/core/styles/makeStyles';
import {
    GRAY_LIGHT, PRIMARY, PRIMARY_DARK,
} from '@theme_color';

const useStyles = makeStyles(() => ({
    primary: {
        borderRadius: 7,
        background: PRIMARY,
        color: '#FFFFFF',
        '&:hover': {
            background: PRIMARY,
        },
        padding: '5px 16px',
        fontWeight: 400,
        textTransform: 'capitalize',
        boxShadow: 'none',
        letterSpacing: 0,
        '&.seller': {
            background: PRIMARY,
            '&:hover': {
                background: PRIMARY_DARK,
            },
        },
    },
    outlined: {
        borderRadius: 7,
        background: 'transparent',
        border: `1px solid ${PRIMARY}`,
        padding: '5px 16px',
        color: PRIMARY,
        fontWeight: 400,
        boxShadow: 'none',
        '&:hover': {
            background: 'transparent',
        },
        textTransform: 'capitalize',
        letterSpacing: 0,
        '&.seller': {
            background: 'none',
            border: `1px solid ${PRIMARY}`,
            '&:hover': {
                border: `1px solid ${PRIMARY_DARK}`,
                background: 'none',
                color: PRIMARY_DARK,
            },
        },
    },
    rounded: {
        borderRadius: 20,
        fontWeight: 400,
        textTransform: 'capitalize',
    },
    disabled: {
        borderColor: 'unset',
        '&.seller': {
            backgroundColor: GRAY_LIGHT,
            color: 'white',
            cursor: 'not-allowed',
            pointerEvents: 'unset',
            '&:hover': {
                backgroundColor: GRAY_LIGHT,
                color: 'white',
            },
        },
    },
    disabledOutlined: {
        borderColor: 'unset',
        '&.seller': {
            backgroundColor: 'none',
            border: `1px solid ${GRAY_LIGHT}`,
            color: GRAY_LIGHT,
            cursor: 'not-allowed',
            pointerEvents: 'unset',
            '&:hover': {
                backgroundColor: 'none',
                border: `1px solid ${GRAY_LIGHT}`,
                color: GRAY_LIGHT,
            },
        },
    },
    buttonText: {
        background: 'none',
        color: PRIMARY,
        textTransform: 'unset',
        letterSpacing: 0,
        boxShadow: 'none',
        '&:hover': {
            background: 'none',
            boxShadow: 'none',
            color: PRIMARY,
        },
        '&.seller': {
            color: PRIMARY,
            '&:hover': {
                color: PRIMARY_DARK,
            },
        },
    },
    link: {
        background: 'none',
        color: '#536777',
        fontWeight: 400,
        textTransform: 'unset',
        letterSpacing: 0,
        boxShadow: 'none',
        '&:hover': {
            background: 'none',
            boxShadow: 'none',
            color: 'rgba(0, 0, 0, 0.87)',
        },
        '&.seller': {
            color: PRIMARY,
            '&:hover': {
                color: PRIMARY_DARK,
            },
        },
    },
    disabledText: {
        borderColor: 'unset',
        color: 'unset',
        background: 'none',
        '&.seller': {
            color: GRAY_LIGHT,
            background: 'none',
            '&:hover': {
                color: GRAY_LIGHT,
            },
        },
    },
}));

export default useStyles;
