/* eslint-disable max-len */
import React from 'react';

const colorWeak = '#FF4B47';
const colorMedium = '#FFA500';
const colorStrong = '#87D6A8';
const colorVeryStrong = '#00C853';

const PasswordStrength = (props) => {
    const {
        passwordErrorStatus,
    } = props;

    const getColor = (status) => {
        switch (status) {
        case 'Weak':
            return colorWeak;
        case 'Medium':
            return colorMedium;
        case 'Strong':
            return colorStrong;
        case 'Very Strong':
            return colorVeryStrong;
        default:
            return colorWeak;
        }
    };

    const getSize = (status) => {
        switch (status) {
        case 'Weak':
            return '25%';
        case 'Medium':
            return '50%';
        case 'Strong':
            return '75%';
        case 'Very Strong':
            return '100%';
        default:
            return '25%';
        }
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '10px 0px',
                width: '100%',
            }}
        >
            <div style={{ height: 5, width: '75%', backgroundColor: '#a3a099' }}>
                <div
                    style={{
                        height: '100%',
                        width: getSize(passwordErrorStatus),
                        backgroundColor: getColor(passwordErrorStatus),
                        transition: 'background-color .2s, visisility .1s',
                    }}
                />
            </div>
            <div style={{ padding: '0px 5px', color: getColor(passwordErrorStatus), fontSize: '0.75rem' }}>
                {passwordErrorStatus}
            </div>
        </div>
    );
};

export default PasswordStrength;
