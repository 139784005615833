import { makeStyles } from '@material-ui/core/styles';
import {
    PRIMARY_DARK, BLUE_INFO_ICON, GREEN_SUCCESS_ICON, YELLOW_WARNING_ICON, RED_ERROR_ICON,
} from '@theme_color';

const useStyles = makeStyles(() => ({
    containerAlert: {
        margin: '20px 0',
        position: 'relative',
        borderRadius: 8,
        '& .MuiAlert-action': {
            alignItems: 'start',
        },
        // severity color
        '&.info': {
            border: `1px solid ${BLUE_INFO_ICON}`,
        },
        '&.success': {
            border: `1px solid ${GREEN_SUCCESS_ICON}`,
        },
        '&.warning': {
            border: `1px solid ${YELLOW_WARNING_ICON}`,
        },
        '&.error': {
            border: `1px solid ${RED_ERROR_ICON}`,
        },
        // align
        '&.start': {
            alignItems: 'start',
        },
        '&.center': {
            alignItems: 'center',
        },
        '&.end': {
            alignItems: 'end',
        },
    },
    divContent: {
        fontSize: 13,
        '&:not(:first-child)': {
            marginTop: 12,
        },
    },
    title: {
        '&.MuiAlertTitle-root': {
            color: PRIMARY_DARK,
            fontWeight: 700,
            fontSize: 13,
            marginBottom: 0,
        },
    },
    icon: {
        '&.info': {
            fill: BLUE_INFO_ICON,
        },
        '&.success': {
            fill: GREEN_SUCCESS_ICON,
        },
        '&.warning': {
            fill: YELLOW_WARNING_ICON,
        },
        '&.error': {
            fill: RED_ERROR_ICON,
        },
    },
}));

export default useStyles;
