import Tooltip from '@material-ui/core/Tooltip';
import useStyles from '@common_tooltipseller/style';

const TooltipSeller = (props) => {
    const className = useStyles();

    const {
        arrow = true, title = '', classes = {}, children, placement = 'top',
    } = props;

    return (
        <Tooltip
            arrow={arrow}
            title={title}
            placement={placement}
            classes={{
                tooltip: className.tooltip,
                tooltipPlacementBottom: className.tooltipPlacementBottom,
                tooltipPlacementTop: className.tooltipPlacementTop,
                arrow: className.tooltipArrow,
                ...classes,
            }}
        >
            {children || <img src="/assets/img/information-circle.svg" alt="tooltip" className={className.tooltipImg} />}
        </Tooltip>
    );
};

export default TooltipSeller;
