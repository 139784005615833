import { useQuery, useMutation, useLazyQuery } from '@apollo/client';
import * as Schema from '@modules/dashboard/services/graphql/schema';

const context = {
    context: { request: 'internal' },
};

export const getCustomer = (variables) => useQuery(Schema.getCustomer, {
    variables, ...context, fetchPolicy: 'no-cache',
});

export const getCustomerLazy = (options) => useLazyQuery(Schema.getCustomer, {
    ...options, ...context, fetchPolicy: 'no-cache',
});

export const getDashboardData = () => useQuery(Schema.getDashboardData, {
    ...context, fetchPolicy: 'cache-and-network',
});

export const getChannelList = () => useQuery(Schema.getChannelList, {
    ...context, fetchPolicy: 'cache-and-network',
});

export const changePassword = (variables) => useMutation(Schema.changePassword, {
    variables, ...context,
});

export const changeEmail = (variables) => useMutation(Schema.changeEmail, {
    variables, ...context,
});

export const changeName = (variables) => useMutation(Schema.changeName, {
    variables, ...context,
});

export const getDashboardDataOrderNew = () => useQuery(Schema.getDashboardDataOrderNew, {
    ...context, fetchPolicy: 'cache-and-network',
});

export const getDashboardDataOrderNoAllocation = () => useQuery(Schema.getDashboardDataOrderNoAllocation, {
    ...context, fetchPolicy: 'cache-and-network',
});

export const getDashboardDataOrderFailed = () => useQuery(Schema.getDashboardDataOrderFailed, {
    ...context, fetchPolicy: 'cache-and-network',
});

export const getDashboardDataShipmentUnTotal = () => useQuery(Schema.getDashboardDataShipmentUnTotal, {
    ...context, fetchPolicy: 'cache-and-network',
});

export const getDashboardDataShipmentUnStorePick = () => useQuery(Schema.getDashboardDataShipmentUnStorePick, {
    ...context, fetchPolicy: 'cache-and-network',
});

export const getDashboardDataShipmentUnHome = () => useQuery(Schema.getDashboardDataShipmentUnHome, {
    ...context, fetchPolicy: 'cache-and-network',
});

export const getDashboardDataShipmentUnMarket = () => useQuery(Schema.getDashboardDataShipmentUnMarket, {
    ...context, fetchPolicy: 'cache-and-network',
});

export const getDashboardDataShipmentCant = () => useQuery(Schema.getDashboardDataShipmentCant, {
    ...context, fetchPolicy: 'cache-and-network',
});

export const getDashboardDataReturnNew = () => useQuery(Schema.getDashboardDataReturnNew, {
    ...context, fetchPolicy: 'cache-and-network',
});

export default {
    getCustomer,
    getCustomerLazy,
    getDashboardData,
    getChannelList,
    changePassword,
    changeEmail,
    changeName,
    getDashboardDataOrderNew,
    getDashboardDataOrderNoAllocation,
    getDashboardDataOrderFailed,
    getDashboardDataShipmentUnTotal,
    getDashboardDataShipmentUnStorePick,
    getDashboardDataShipmentUnHome,
    getDashboardDataShipmentUnMarket,
    getDashboardDataShipmentCant,
    getDashboardDataReturnNew,
};
