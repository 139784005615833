import { gql } from '@apollo/client';

export const getCustomerToken = gql`
mutation getToken(
    $email: String!,
    $password: String!,
) {
  internalGenerateCustomerToken(email: $email, password: $password){
      token
    }
  }
`;

export const removeToken = gql`
mutation {
  internalDeleteCustomerToken{
    result
  }
}
`;

export const getCustomer = gql`
    query {
        customer {
            email
            firstname
            lastname
            customer_loc_code
            channel_code
            customer_company_code
            force_reset_password
            phone_number
            group {
                code
                id
            }
        }
    }
`;

export const getSeller = gql`
query getSeller {
  getSeller {
    description
    id
    is_company_profile_complete
    is_finish_product_complete
    logo
    name
    seller_path
    status
    email
    summary_transaction {
      total_order
    }
  }
}
`;

export const getStoreConfig = gql`
  query getStoreConfig($path: String!){
    getStoreConfig(path: $path)
  }
`;

export default {
    getCustomerToken,
    removeToken,
    getCustomer,
    getSeller,
    getStoreConfig,
};
